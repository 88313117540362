import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import Image from '../components/Image';

// There are anchor tags in the desc.
// We need to handle them properly
// Either avoid them or manually write a component for every book
const books = [
  {
    img: 'books/kespa.jpg',
    hash: 'kespa',
    title: 'Как это сказать по-английски',
    desc:
      '<p>Учебник И.Гивенталь <i>“Как это сказать по-английски”</i> был впервые опубликован в 2003 году и с тех пор переиздавался 16 раз. Название учебника полностью выражает главную идею методики КЭСПА – идею изучения <i>чужого</i> (английского) языка методом отталкивания от <i>родного</i> (русского). В книге впервые сформулированы базовые понятия методики: прагмалингвистическая формула, артикуляционная память и техника речи.</p><p>Учебник <i>“Как это сказать по-английски”</i> предназначен и для тех, кто только-только начал изучать английский язык, и для тех, кто уже много лет пытается научиться говорить по-английски, но так и не может разобраться в сложностях употребления английских грамматических времен.</p><p><a class="btn btn-nice" href="http://www.ozon.ru/context/detail/id/20980198/">Книга на ozon.ru</a></p>',
  },
  {
    img: 'books/rechejer.jpg',
    hash: 'rechejer',
    title: 'Речевой тренажер или Как говорить по-английски, не запинаясь',
    desc:
      '<p><i>“Речевой тренажер”</i> – это не традиционный учебник с последовательным объяснением грамматических тем и упражнениями. Это своеобразный тренажерный зал, в котором  «установлены» 124 текста-тренажера для развития навыка беглого и грамотного говорения на английском языке. Вы можете «подойти» к любому и, отрабатывая определенную грамматическую конструкцию, заодно пополнять свой словарный запас нужной общеупотребительной разговорной лексикой.</p><p><i>“Речевой тренажер”</i> создан для людей разных психологических типов, с разными речевыми привычками. Учебник очень пригодится преподавателям, которых волнует проблема индивидуального подхода к обучению. Книга написана в соавторстве с КЭСПА-преподавателем Ольгой Жиронкиной.</p><p><a class="btn btn-nice" href="http://www.ozon.ru/context/detail/id/29483951/">Книга на ozon.ru</a></p>',
  },
  {
    img: 'books/s_nulya.jpg',
    hash: 's_nulya',
    title: 'Английский с нуля для детей и взрослых',
    desc:
      '<p>Эта книга для неспешной и тщательной работы, своеобразная КЭСПА-терапия для всех, кто готов начать учить английский в любом возрасте — от 7 до 107 лет. Особенно она будет полезна:</p><ul><li>взрослым, которые хотят помочь своим детям в освоении английского и параллельно структурировать свои знания и заговорить, наконец, по-английски;</li><li>детям, которые только-только начали изучать английский язык в школе или в детском саду, для их старших братьев и сестер, которые считают, что труднее и скучнее английского может быть только математика на китайском;</li><li>бабушкам и дедушкам, которые хотят убежать от маразма, не принимая таблеток для улучшения памяти;</li><li>учителям начальной и средней школы — как образец альтернативного метода обучения английскому языку;</li><li>семейным психологам — в качестве примера конструктивной совместной деятельности людей разного возраста.</li></ul><p>Учебник написан  в соавторстве с КЭСПА-преподавателем Алисой Задорожной.</p><p><a class="btn btn-nice" href="http://www.ozon.ru/context/detail/id/20102947/">Книга на ozon.ru</a></p>',
  },
  {
    img: 'books/grammajer.jpg',
    hash: 'grammajer',
    title:
      'Грамматический тренажер. Как правильно говорить и писать по-английски',
    desc:
      '<p><i>"Грамматический тренажер"</i> — это справочник с упражнениями по всем разделам английской грамматики, разработанный в соответствии с принципами авторской образовательной технологии КЭСПА.</p><p>Для эффективной работы с учебником надо прежде всего перевести с русского на английский предложения из диагностического теста "Проверь себя сам", чтобы определить, какие разделы грамматики у вас "хромают", и именно с этих тем начать работать над уроками. Каждый урок состоит из теоретического материала с выделением случаев, в которых русскоязычные ученики особенно часто ошибаются, и упражнений-тренировок.</p><p>Учебник, написанный в соавторстве с КЭСПА-преподавателем Павлом Ремизовым, просто незаменим, если нужно быстро ликвидировать пробелы в грамматике, систематизировать отрывочные знания, разложить все по полочкам перед любым экзаменом на владение английским (ЕГЭ, TOEFL, IELTS).</p><p><a class="btn btn-nice" href="http://www.ozon.ru/context/detail/id/135028812/">Книга на ozon.ru</a></p>',
  },
  {
    img: 'books/kuivpa.jpg',
    hash: 'kuivpa',
    title: 'Как удивиться и возмутиться по-английски',
    desc:
      '<p>В этой книге автор уделяет особое внимание  способам выражения эмоциональных состояний говорящего и подробно  рассматривает различные случаи употребления  модальных глаголов английского языка. И.Гивенталь, как обычно, уделяет большое внимание системе артикуляционно-грамматических упражнений. Книга пользуется особой популярностью среди учеников уровней intermediate и advanced.</p><p><a class="btn btn-nice" href="http://www.ozon.ru/context/detail/id/31658384/">Книга на ozon.ru</a></p>',
  },
];

const Book = ({ img, desc, title, hash }) => (
  <div className="landing">
    <div className="row m-b">
      <div className="col-sm-3">
        <Image src={img} alt={title} className="img-book" />
      </div>
      <div className="col-sm-8 col-sm-offset-1">
        <h2 id={hash}>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </div>
  </div>
);

Book.propTypes = {
  img: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
};

const Publications = () => (
  <div className="publishings-wrapper page-wrapper container">
    <Helmet title="КЭСПА – Книги" />
    <h1>Книги по методике</h1>
    {books.map((book, i) => (
      <Book key={i} {...book} />
    ))}
  </div>
);

export default wrapInLayout(wrapInSection(Publications));
